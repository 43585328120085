import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { heading } from '../../theme/theme';
const ImageSlider = ({ projectDetails }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
// console.warn("images in slider",projectDetails.image_gallery )
const images = projectDetails.image_gallery || []; 

  return (
    <>
                <Typography sx={{...heading}}>Image Gallery</Typography>
    <Box sx={{ display: 'flex', border:"1px solid #d7c3a8" }}>
      <Box sx={{ flex: 1 }}>
        {/* Main image slider */}
        <Box sx={{ display: 'flex', height: '50vh' }}>
  {/* Image container */}
  <Box sx={{ position: 'relative', overflow: 'hidden', flex: '1' }}>
    {projectDetails.image_gallery.map((imageObj, index) => (
      <Box
        key={index}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: index === currentImageIndex ? '100%' : '0',
          opacity: index === currentImageIndex ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <img src={imageObj.file_name} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
    ))}
  </Box>

  {/* Image preview column */}
  <Box sx={{ flex: '0 0 15%', backgroundColor: '#f0f0f0', overflow: 'scroll', scrollbarWidth: 'none', '-ms-overflow-style': 'none', border:"2px solid #fff" }}>
    {projectDetails.image_gallery.map((imageObj, index) => (
      <img
        key={index}
        src={imageObj.file_name}
        alt={`Preview ${index + 1}`}
        style={{ width: '114px', marginBottom: 2, cursor: 'pointer' }}
        onClick={() => setCurrentImageIndex(index)}
      />
    ))}
  </Box>
</Box>
</Box>
</Box>

    </>
  );
};

export default ImageSlider;
