import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Client from "./axios";

const OurListings = () => {
  const [outListing, setOurListing] = useState([]);

  useEffect(() => {
    Client.post("/cityluxedxb/our_listing.php")
      .then((response) => {
        if (response.data.errorcode == 200) {
          setOurListing(response.data.datalist);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const pluralize = (count, singular, plural) => {
    if (singular.toLowerCase() === "duplex") {
      return parseInt(count) === 1 ? singular : "Duplexes";
    }
    return parseInt(count) === 1 ? singular : plural;
  };


  const ourlistingUnit = {
    color: "#333",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "'Nunito Sans', sans-serif",
    cursor: "pointer",
    transition: "0.2s",
    "&:hover": {
      color: "cutome.lightwheat",
    },
  };

  const ourlistingstyleNo = {
    color: "#5c727d",
    fontSize: "13px",
    fontWeight: "600",
    fontFamily: "'Nunito Sans', sans-serif",
  };
 
  return (
    <>
      <Box sx={{}}>
        {outListing.map((val, index) => {
          const pluralizedType = pluralize(val.COUNT, val.Unit_Type, `${val.Unit_Type}s`);
          return (
            <Stack
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #f0f0f0",
                py: "10px",
              }}
            >
              <Typography variant="body2" sx={{ ...ourlistingUnit }}>
                <Link
                  to={`/viewlisting?type=${
                    val.Unit_Type
                  }&timestamp=${new Date().getTime()}`}
                  style={{
                    color: "inherit",
                    textDecoration: "inherit",
                    cursor: "pointer",
                  }}
                >
                  {pluralizedType}
                </Link>
              </Typography>

              <Typography variant="body2" sx={{ ...ourlistingstyleNo }}>
                &#40;{val.COUNT}&#41;
              </Typography>
            </Stack>
          );
        })}
      </Box>
    </>
  );
};

export default OurListings;
