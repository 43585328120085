import React from 'react';
import { Paper, Typography, Grid, Box, Avatar } from '@mui/material';

const recentBlogPosts = [
  {
    id: 1,
    image: 'image1.jpg',
    title: 'Lorem Ipsum Dolor Sit Amet',
    date: 'Oct 15, 2023',
  },
  {
    id: 2,
    image: 'image2.jpg',
    title: 'Consectetur Adipiscing Elit',
    date: 'Oct 10, 2023',
  },
  {
    id: 3,
    image: 'image3.jpg',
    title: 'Pellentesque Nec Nunc Sit Amet',
    date: 'Oct 5, 2023',
  },
  {
    id: 4,
    image: 'image1.jpg',
    title: 'Lorem Ipsum Dolor Sit Amet',
    date: 'Oct 15, 2023',
  },
  {
    id:5 ,
    image: 'image2.jpg',
    title: 'Consectetur Adipiscing Elit ',
    date: 'Oct 10, 2023',
  },
  {
    id: 6,
    image: 'image3.jpg',
    title: 'Pellentesque Nec Nunc Sit Amet',
    date: 'Oct 5, 2023',
  },
];

function RecentBlogPosts() {
  return (
    <Paper elevation={3} >
      <Typography variant="h6" sx={{borderBottom:".7px solid #00000018", marginTop:"25px", padding:"16px"}}>
        RECENT BLOGS POSTS
      </Typography>
     <Box sx={{ marginTop: '25px', padding: '16px' }}> <Grid container spacing={2}>
        {recentBlogPosts.map((post) => (
          <Grid item xs={12} sm={12} key={post.id}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={post.image}
                alt={post.title}
                variant="rounded"
                sx={{ width: 75, height: 75, marginRight: 2 }}
              />
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {post.date}
                </Typography>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid></Box>
    </Paper>
  );
}

export default RecentBlogPosts;
