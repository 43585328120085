import React, { createContext, useContext, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Home from "./pages/Home";
import Properties from "./pages/properties";
import LatestProjects from "./pages/LatestProjects";
import Team from "./pages/team";
import SingleTeamMember from "./pages/single_team_member";
import ListProperties from "./pages/list_properties";
import Contact from "./pages/contact";
import Page404 from "./pages/404";
import SinglePropertyDetails from "./pages/single_property_details";
import SearchProperties from "./pages/searchProperties";
import AllPremiumSuperHotFeatured from "./pages/all_premium_super_hot_featured";
import PrivacyPolicy from "./pages/privacy-policy";
import TermOfUse from "./pages/terms-of-use";
import Login from "./pages/adminpanel/login/login";
import Dashboard from "./pages/adminpanel/dashboard/dashbord";
import TeamMember from "./pages/adminpanel/dashboard/team_members";
import PropertyType from "./pages/adminpanel/dashboard/property_type";
import ListingUnitType from "./pages/listing_unit_type";
import ChangePassword from "./pages/adminpanel/dashboard/change_password";
import AllProjects from "./pages/adminpanel/dashboard/all_projects";
import UserContextCurrency from "./context/currencyChange";
import AOS from "aos";
import "aos/dist/aos.css";
import MainFooter from "./components/main_footer";
import FooterLinksResult from "./components/FooterLinksResult";
import BlogsList from "./pages/Blogs/BlogsList";
import SingleBlogDetails from "./pages/Blogs/SingleBlogDetails";
import Projects from "./pages/Projects/Projects";
import SingleProjectDetails from "./pages/Projects/SingleProjectDetails";
import AgentWiseProperties from "./pages/AgentWiseProperties";
import { ProjectDetailsProvider } from "./pages/Projects/ProjectDetailsContext";
import ProjectsByCategory from "./pages/Projects/ProjectsByCategory";
import AllProjectsView from "./pages/Projects/AllProjectsView";
import AllProjectsBySame from "./pages/Projects/AllProjectsBySame";
import SearchedResultProjects from "./pages/Projects/SearchedResultProjects";
import { BlogProvider } from "./pages/Blogs/BlogContext";
import { CategoryProvider } from "./pages/Blogs/CategoryProvider";
import BlogsByCategory from "./pages/Blogs/BlogsByCategory";
import { DeveloperProvider} from "./pages/Projects/DeveloperContext";
import { CommunityProvider } from "./pages/Projects/CommunityContext";
import { SearchProvider } from "./pages/Projects/SearchContext";
import { RecordsProvider } from "./pages/FilteredRecordsContext";
export const FooterContext = createContext();
export const FirstImageContext = createContext();
export const LinkTextContext = createContext();
export const ApiDataContext = createContext();
export const useFooterContext = () => useContext(FooterContext);
export const useFirstImageContext = () => useContext(FirstImageContext);
export const useLinkTextContext = () => useContext(LinkTextContext);
export const useApiDataContext = () => useContext(ApiDataContext);

function App() {
  const [value, setValue] = useState(false);
  const [currencySign, setCurrencySign] = useState("AED");
  const [areaType, setAreaType] = useState("SQFT");
  const [linkData, setLinkData] = useState([]);
  const [linkText, setLinkText] = useState([]);
  const [firstImage, setFirstImage] = useState(null);
  const [apiData, setApiData] = useState([]);

  const updateApiData = (newData) => {
    setApiData(newData);
  };
  const updateFirstImage = (image) => {
    setFirstImage(image);
  };

  AOS.init();
  const [scroll, setscroll] = useState(false);
  function callhandlar() {
    if (window.scrollY > 50) {
      setscroll(true);
    } else {
      setscroll(false);
    }
  }
  window.addEventListener("scroll", callhandlar);
  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <UserContextCurrency.Provider
        value={{
          currencySign,
          setCurrencySign,
          value,
          setValue,
          areaType,
          setAreaType,
        }}>
        {/* <UserContext.Provider value={{ value, setValue }}> */}
        <ApiDataContext.Provider value={{ apiData, updateApiData }}>
          {" "}
          {/* Add the new context provider */}
          <FooterContext.Provider value={{ linkData, setLinkData }}>
            <FirstImageContext.Provider
              value={{ firstImage, updateFirstImage }}>
                  <CategoryProvider>
            <BlogProvider>
            <SearchProvider>
            <ProjectDetailsProvider>
              <CommunityProvider>
              <DeveloperProvider>
                <RecordsProvider>
              <LinkTextContext.Provider value={{ linkText, setLinkText }}>
              <Routes basename="/">
                  <Route path="" exact element={<Home scroll={scroll} matches={matches} />}/>
                  <Route path="/properties" element={<Properties scroll={scroll} matches={matches} />} />
                  <Route path="/agent_wise_properties" element={ <AgentWiseProperties scroll={scroll} matches={matches} />}/>
                  <Route path="/properties/:title" element={ <SinglePropertyDetails scroll={scroll} matches={matches} /> } />
                  <Route path="/team" element={<Team scroll={scroll} matches={matches} />} /> 
                  <Route path="/team-member-details/:agentID" element={ <SingleTeamMember scroll={scroll} matches={matches} /> }/>
                  <Route path="/list property" element={ <ListProperties scroll={scroll} matches={matches} /> } />
                  <Route path="/contact" element={<Contact scroll={scroll} matches={matches} />} /> 
                  <Route path="/privacy-policy" element={ <PrivacyPolicy scroll={scroll} matches={matches} /> } />
                  <Route path="/terms-of-use" element={<TermOfUse scroll={scroll} matches={matches} />} />
                  <Route path="/search" element={ <SearchProperties scroll={scroll} matches={matches} /> } />
                  <Route path="/premiumsuperhotfeatured" element={ <AllPremiumSuperHotFeatured scroll={scroll} matches={matches} /> } />
                  <Route path="/viewlisting" element={ <ListingUnitType scroll={scroll} matches={matches} /> }/> 
                  <Route path="/footerLinksResult" element={ <FooterLinksResult scroll={scroll} matches={matches} /> } />
                  <Route path="/projects" element={<Projects scroll={scroll} matches={matches} />} />
                  <Route path="/projects/all projects" element={<AllProjectsView scroll={scroll} matches={matches} />} />
                  <Route path="/projects/:category" element={<ProjectsByCategory scroll={scroll} matches={matches}/>} />
                  <Route path="/projects/single-project-details/:id" element={ <SingleProjectDetails scroll={scroll} matches={matches} /> } />
                  <Route path="/projects/single-project-details/all-projects-by-filter" element={ <AllProjectsBySame scroll={scroll} matches={matches} /> } />
                  <Route path="/projects/:category" element={ <ProjectsByCategory scroll={scroll} matches={matches} /> } />
                  <Route path="/search-result-projects" element={ <SearchedResultProjects scroll={scroll} matches={matches} /> } />
                  <Route path="/latestProjects" element={ <LatestProjects scroll={scroll} matches={matches} /> } />
                  <Route path="/blogs" element={<BlogsList scroll={scroll} matches={matches} />} />
                  <Route path="/blogs/:id" element={ <SingleBlogDetails scroll={scroll} matches={matches} />}/>
                  <Route path="/blogs/blogs-by-category" element={ <BlogsByCategory scroll={scroll} matches={matches} />}/>
                  <Route path="/adminpanel/login/login" element={<Login />} />
                  <Route path="/adminpanel/dashboard/dashboard" element={<Dashboard />}/>
                  <Route path="/adminpanel/dashboard/team" element={<TeamMember />}/>
                  <Route path="/adminpanel/dashboard/property_type" element={<PropertyType />}/>
                  <Route path="/adminpanel/dashboard/change_password" element={<ChangePassword />}/>
                  <Route path="/adminpanel/dashboard/allprojects" element={<AllProjects />} />
                  <Route path="/" element={<MainFooter />} />
                  <Route path="*" element={<Page404 />} />
                  <Route path="/*" element={<Page404 />} />
                  {/* <Route path="*" element={<Navigate to={<Page404 />} />} /> */}
                </Routes>
              </LinkTextContext.Provider>
              </RecordsProvider>
              </DeveloperProvider>
              </CommunityProvider>
               </ProjectDetailsProvider>
               </SearchProvider>
              </BlogProvider>
              </CategoryProvider>
            </FirstImageContext.Provider>
          </FooterContext.Provider>
          {/* </UserContext.Provider> */}
        </ApiDataContext.Provider>
      </UserContextCurrency.Provider>
    </>
  );
}

export default App;
