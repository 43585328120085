import React, { useEffect, useState, useContext } from "react";
import { cardstyle } from "../theme/theme";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import Delar_img from "../assests/logo_img/delar.png";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ImgCrousel from "./img_crousel";
import {useNavigate } from "react-router-dom";
import { numberWithCommas } from "./subcomponent/global_functions";
import Client from "./axios";
import UserContextCurrency from "../context/currencyChange";

const heading = {
  textTransform: "capitalize",
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: "700",
  fontSize: "19px",
  lineHeight: "25px",
  color: "#222",
  cursor: "pointer",
  transition: "0.5s",
  "&:hover": {
    color: "cutome.lightwheat",
  },
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
};


const subheading = {
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: "700",
  fontSize: "18px",
  textTransform: "uppercase",
  color: "cutome.lightwheat",
};

const CardView = (props) => {
  const { listitem, handleCardClick } = props;
  const firstImage = listitem.Images[0];
  const [retryCount, setRetryCount] = useState(0);
  // const httpFirstImage = firstImage.replace(/^https:/, "http:");


  const handleImageError = () => {
    if (retryCount < 2) { // You can change 2 to the desired number of retries
      setRetryCount(retryCount + 1);
    }
  };
  const handleClick = () => {
    if (typeof props.handleCardClick === "function") {
      props.handleCardClick(firstImage);
// console.warn("url of first image ", firstImage)      
    }
  };

  const {
    currencySign,
    setCurrencySign,
    value,
    setValue,
    areaType,
    setAreaType,
  } = useContext(UserContextCurrency);
  const [memberDetails, setMemberDetails] = useState({});

  // conditon for price currency change
  let item = props.listitem;
  // console.warn("items data", item);
  let price = 0;
  let priceCurrency = "";

  if (currencySign == "EUR") {
    // 0.25
    priceCurrency = "EUR";
    price = numberWithCommas(Math.round(parseFloat(item.Price) * 0.25));
  } else if (currencySign == "GBP") {
    // 0.22
    priceCurrency = "GBP";
    price = numberWithCommas(Math.round(parseFloat(item.Price) * 0.22));
  } else if (currencySign == "USD") {
    // 0.27
    priceCurrency = "USD";
    price = numberWithCommas(Math.round(parseFloat(item.Price) * 0.27));
  } else if (currencySign == "INR") {
    // 22.26
    priceCurrency = "INR";
    price = numberWithCommas(Math.round(parseFloat(item.Price) * 22.35));
  } else {
    priceCurrency = "AED";
    price = numberWithCommas(Math.round(item.Price));
  }


  let areaSize = 0;
  let TypeArea = "";

  if (areaType == "SQFT") {
    // 0.25
    TypeArea = "sqft";
    areaSize = numberWithCommas(Math.round(parseFloat(item.Unit_Builtup_Area)));
  } else if (areaType == "SQM") {
    // 0.22
    TypeArea = "sqm";
    areaSize = numberWithCommas(
      Math.round(parseFloat(item.Unit_Builtup_Area) * 0.09290304)
    );
  }

  let images = item.Images.slice(0, -1);
  let cardwidth = props.cardwidth;
   item.Images = images;
   const httpImages = item.Images.map(imageUrl => imageUrl.replace(/^https:/, 'http:'));
   
   useEffect(() => {
    console.log('useEffect running');
    let listing_Agent_Name = item.Listing_Agent;
  
    if (listing_Agent_Name) {
      listing_Agent_Name = listing_Agent_Name.replace(/  +/g, " ");
      Client.post("/cityluxedxb/listing_agent.php", {
        agentName: listing_Agent_Name,
      })
        .then((response) => {
          if (response.data.errorcode == 200) {
            setMemberDetails(response.data.teamList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.error('Listing_Agent is null or undefined');
    }
  }, [item.Listing_Agent]);
  
  const navigate = useNavigate();

  let single_pro_details = () => {
    let title = item.Property_Title.replace("/", "");
    navigate(`/properties/${title}`, {
      state: { item: item, member: memberDetails },
    });
  };

  let single_team_member_details = () => {
    navigate(`/team-member-details/${memberDetails.id}`);
  };

  const textHeading = {
    fontSize: "13px",
    color: "#5c727d",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: "400",
    textTransform: "Uppercase",
  };
  const formattedFrequency = item.Frequency.replace("Per Year", "/Year");

  useEffect(() => {
    if (retryCount > 0) {
      setRetryCount(0);
    }
  }, [retryCount]);
  return (
    <>
      <Card
        sx={{
          ...cardstyle,
          maxWidth: { xs: "100vw", md: cardwidth },
          height: "inherit",
          width:"-webkit-fill-available",
          mb: { xs: "20px", md: "20px" },
        }}
        onClick={handleClick}
      >
        <CardActionArea onClick={() => single_pro_details()}>
          <Box sx={{ width: "100%" }} className="CardImage">
             <ImgCrousel 
            allimage={httpImages}
            onImageError={httpImages}
            retryCount={retryCount}
             />
            <Box
              sx={{
                padding: "2px 5px",
                position: "absolute",
                left: "15px",
                top: "10px",
                color: "#4c4f54",
                background: "#d7c3a8",
                fontSize: "10px",
                fontWeight: "500",
                fontFamily: "'Roboto', sans-serif",
                textTransform: "uppercase",
              }}
            >
              {item.Ad_Type}
            </Box>
          </Box>

          <CardContent onClick={() => single_pro_details()}>
            <Typography gutterBottom variant="h6" component="div" sx={heading}>
              {item.Property_Title}
            </Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={{ ...subheading, mb: "0px", lineHeight: "1em" }}
            >
              {priceCurrency} {price}
              <span style={{fontSize:"11px", fontWeight:"bold"}}> {formattedFrequency}</span> 

            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...textHeading,
                height: "35px",
              }}
            >
              {item.Property_Name}, {item.Community}
            </Typography>

            <Stack direction="row" spacing={2} sx={{ mt: "0px" }}>
              <Stack direction="row" spacing={0}>
                <SquareFootIcon fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "700",
                    color: "custom.dark2",
                  }}
                >
                  {/* {item.Unit_Builtup_Area} sqft */}
                  {areaSize} {TypeArea}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={0}>
                <BathtubOutlinedIcon fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "700",
                    color: "custom.dark2",
                  }}
                >
                  {item.No_of_Bathroom}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={0}>
                <BedOutlinedIcon fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "700",
                    color: "custom.dark2",
                  }}
                >
                  {item.Bedrooms}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={0}>
                <ApartmentIcon fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "700",
                    color: "custom.dark2",
                  }}
                >
                  {item.Unit_Type}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>

          <CardActions
            sx={{
              justifyContent: "space-between",
              borderTop: "1px solid #d8d8d8",
              px: "13px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                border: "1px solid #d8d8d8",
              }}
            >
              <CardMedia
                component="img"
                height="30px"
                width="30px"
                image={item.Listing_Agent_Photo ? item.Listing_Agent_Photo : (memberDetails.image ? memberDetails.image : Delar_img)}
                // image={item.Listing_Agent_Photo}
                alt="Agent Photo"
                onClick={() => single_team_member_details()}
              />
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontFamily: "'Nunito Sans', sans-serif",
                  fontWeight: "700",
                  color: "custom.dark2",
                }}
                onClick={() => single_team_member_details()}
              >
                {item.Listing_Agent}
              </Typography>
            </Box>
            <Box>
              <IconButton aria-label="share post">
                <ShareIcon
                  fontSize="small"
                  sx={{
                    fontSize: "20px",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "700",
                    color: "custom.dark2",
                  }}
                />
              </IconButton>
            </Box>
          </CardActions>
        </CardActionArea>
      </Card>
    </>
  );
};
CardView.propTypes = {
  listitem: PropTypes.object.isRequired,
  cardwidth: PropTypes.string.isRequired,
  handleCardClick: PropTypes.func.isRequired,
};

export default CardView;




