import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import { FaCircleArrowUp } from "react-icons/fa6";
import { useProjectDetails } from './ProjectDetailsContext';
import UserContextCurrency from '../../context/currencyChange';
import { numberWithCommas } from '../../components/subcomponent/global_functions';

const ProjectsCards = ({ projects }) => {
  const navigate = useNavigate(); 
  const { setProjectDetails } = useProjectDetails(); 
  const [loading, setLoading] = useState(false);

  const styles = {
    projectCard:{
border:"1px solid #e1d3bc",
boxShadow:"none",
textDecoration:"none",
minHeight:"444px",
width: { xs: "100%", sm:"100%", md: "256px" },

    }
  }
  const {
    currencySign,
    setCurrencySign,
    value,
    setValue,
    areaType,
    setAreaType,
  } = useContext(UserContextCurrency);
  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://cityluxedxb.com/cityluxedxb/getProjectsdetails.php?id=${id}`);
      const data = response.data;
  
      // console.log('Project Details Data:', data); 
      
      navigate(`/projects/single-project-details/${id}`, { state: { projectDetails: data } });
      setProjectDetails(data); 
    } catch (error) {
      console.error('Error fetching project details:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleProjectClick = (id) => {
    fetchData(id);
  };
  const convertCurrency = (price) => {
    switch (currencySign) {
      case 'EUR':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.25));
        case 'GBP':
      case 'GBP':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.22));
      case 'USD':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.27));
      case 'INR':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 22.35));
      default:
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, ''))));
    }
  };
  useEffect(() => {
  }, [projects]);
  return (
    
    <Grid container spacing={2}> 
      {projects && projects.length > 0 ? (
        projects.map((project) => (
          <Grid item key={project.id} onClick={() => handleProjectClick(project.id)} sx={{cursor:"pointer"}} >
            <Card sx={styles.projectCard} >
              <CardMedia sx={{ height: "200px", textTransform: "uppercase", '&:hover': {transform: 'scale(1.2)', transition: 'transform 0.3s ease-in-out'}, }} component="img" alt="" height="140" image={project.file_name} />
              <CardContent>
                <Typography sx={{height:"54px", fontSize: "18px", fontWeight: "100", mb: "3", textTransform: "uppercase", '&:hover': {color: "#e1d3bc" }, }}>{project.title}</Typography>
                <Box sx={{ display: "flex" }}>
                  <FaCircleArrowUp fill='#d7c3a8' style={{ marginRight: "5px", marginTop: "3px" }} />  
                  <Typography sx={{ fontSize: "16px", fontWeight: "100", mb: "3" }}>  {currencySign}&nbsp;{convertCurrency(project.price)}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "100", marginTop: "5px", marginBottom: "5px", textTransform: "uppercase", minHeight:"37px" }}>LOCATION: {project.location}</Typography>
                </Box>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>Type: {project.unit_type}</Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>BY {project.developer_name}</Typography>
                <Button variant="contained" size='small' color="primary" onClick={() => handleProjectClick(project.id)} sx={{ marginTop: "15px", borderRadius: "0", width: "100%" }}>
                  Explore
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1">No projects available.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectsCards;
