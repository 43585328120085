import React, { createContext, useState, useEffect } from 'react';
import Axios from 'axios';

const BlogContext = createContext();

const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
  const [blogAreas, setBlogAreas] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);

  useEffect(() => {
    Axios.post("https://cityluxedxb.com/cityluxedxb/all_blogs.php")
      .then((response) => {
        const blogs = response.data.blogslist;
        setBlogs(response.data.blogslist);
        const areas = [...new Set(blogs.map(blog => blog.blogarea))];
        const categories = [...new Set(blogs.map(blog => blog.blogcategory))];
        setBlogAreas(areas);
        setBlogCategories(categories);
      })
      .catch((error) => {
        console.error("Error fetching blog data: ", error);
      });
  }, []);
  
  const filterBlogsByCategory = (category) => {
    const filteredBlogs = blogs.filter(blog => blog.blogcategory === category);
    console.log(`Filtered blogs for category ${category}:`, filteredBlogs);
    return filteredBlogs;
  };

  return (
    <BlogContext.Provider value={{ blogAreas, blogCategories, blogs, filterBlogsByCategory  }}>
      {children}
    </BlogContext.Provider>
  );
};

export { BlogContext, BlogProvider };
